body{
    color: white;
    bottom: 30px;

}

.thirtp{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
    width: 100%;
    font-weight: 700;
    font-size: large;
    line-height: 200%;
}

.thirtpp{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    background-color: black;
}

.th{
    align-self: center;
    font-weight: 700;
    font-size: x-large;
    line-height: 200%; 
}
.spacer{
    width: 100%;
    height: 50px;
    background-color: black;
}
.red{
    width:32%;
    color: red;
    background-color: white;
    font-weight: 900;
}
.tt{
    color: yellow;
    font-weight: 900;
    font-size: xx-large;
}
.ttt{
    color: red;
    font-weight: 900;
    font-size: xx-large; 
}

@media only screen and (max-width: 480px) {

    body{
        color: white;
        bottom: 30px;
    
    }
    
    .thirtp{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 50%;
        width: 100%;
        font-weight: 700;
        font-size: large;
        line-height: 200%;
    }
    
    .thirtpp{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 90%;
        background-color: black;
    }
    
    .th{
        align-self: center;
        font-weight: 700;
        font-size: x-large;
        line-height: 200%; 
    }
    .spacer{
        width: 100%;
        height: 50px;
        background-color: black;
    }
    .red{
        width:100%;
        color: red;
        background-color: white;
        font-weight: 900;
    }
    .tt{
        color: yellow;
        font-weight: 900;
        font-size: xx-large;
    }
    .ttt{
        color: red;
        font-weight: 900;
        font-size: xx-large; 
    }
  
  }