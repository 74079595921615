body{
    color: white;
    bottom: 30px;

}

.mommyp{
    height: 50%;
    width: 65%;
    font-weight: 700;
    font-size: large;
    line-height: 200%;
}

.mp{
    display:flex;
    justify-content: center;
    align-items: center;
}

.lh{
    align-self: center;
    font-weight: 700;
    font-size: x-large;
    line-height: 200%; 
}