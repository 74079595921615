.body{
    color: yellow;
}
.hp{
    display:flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    background-image: url('background.png');
    background-size: 60%; /* Ensures the image covers the entire area */
    height: 100%;
    background-position: center; /* Centers the image */
}
.toptwo{
    display: flex;
}
.hpp{
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: 900;
    font-size: large;
    line-height: 200%;
    color: white;
}
.hh{
    width:30%;
    align-self: center;
    font-weight: 700;
    font-size: x-large;
    line-height: 200%; 
}
.grid1{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 1;
}
.grid2{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 2;
}
.grid3{
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
}
.fpright{
    display: grid;
    right: 0%;
    top: 20%;
}

@media only screen and (max-width: 480px) {

    .body{
        color: yellow;
    }
    .hp{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        background-image: url('background.png');
        background-size: 60%; /* Ensures the image covers the entire area */
        height: 100%;
        background-position: center; /* Centers the image */
    }

    .hpp{
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        font-weight: 900;
        font-size: large;
        line-height: 200%;
        color: white;
    }
    .hh{
        display: flex;
        flex-direction: row;
        align-self: center;
        font-weight: 700;
        font-size: x-large;
        line-height: 200%; 
        width: 100%;
    }
    
    .grid1{
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
    }
    .grid2{
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 2;
    }
    .grid3{
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .fpright{
        display: grid;
        right: 0%;
        top: 0%;
    }
    .per{
        width:95%
    }
}