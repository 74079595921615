

.ob-card{
  color: black;
  background-color: white;
  height: 475px;
  width: 275px;
  border: 4px solid hsl(2, 0%, 80%); /* Changed border syntax */
  border-radius: 10px;
  box-shadow: 5px 5px 5px hsla(0, 0%, 100%, 0.1);
  padding: 10px;
  margin: 20px;
  display: flex; /* Added */
  flex-direction: column; /* Added if you want vertical centering */
  align-items: center; /* Centers horizontally */
  justify-content: center; /* Centers vertically */
  font-weight: 600;
    
}

.ob-grid-container {
  margin-left: 10%;
  margin-right: 10%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Example: 3 equal columns */
  gap: 50px; /* Combined column-gap and row-gap */
  justify-items: center; /* Centers items horizontally within their grid area */
  align-items: center; /* Centers items vertically within their grid area */
  }

/* Grid Items */
.ob-grid-item1 { grid-column: 1; }
.ob-grid-item2 { grid-column: 2; }
.ob-grid-item3 { grid-column: 3; }
.ob-grid-item4 { grid-row: 2; grid-column: 1; }
.ob-grid-item5 { grid-row: 2; grid-column: 2; }
.ob-grid-item6 { grid-row: 2; grid-column: 3; }


.hpbg{
  background-image: url('background.png');
  background-size: 60%; /* Ensures the image covers the entire area */
  height: 100%;
  background-position: center; /* Centers the image */
}

/* Responsive styles for mobile screens */
@media only screen and (max-width: 480px) {
  .ob-grid-container {
    margin:0;
    grid-template-columns: 1fr; /* Single column layout */
    gap: 10px; /* Adjust gap for smaller screens */
  }

  .ob-grid-item1 { grid-row: 1; grid-column: 1; }
  .ob-grid-item2 { grid-row: 2; grid-column: 1; }
  .ob-grid-item3 { grid-row: 3; grid-column: 1; }
  .ob-grid-item4 { grid-row: 4; grid-column: 1; }
  .ob-grid-item5 { grid-row: 5; grid-column: 1; }
  .ob-grid-item6 { grid-row: 6; grid-column: 1; }

  /* Card Styles */
.ob-card {
  color: black;
  background-color: white;
  height: 475px;
  width: 275px;
  border: 4px solid hsl(2, 0%, 80%);
  border-radius: 10px;
  box-shadow: 5px 5px 5px hsla(0, 0%, 100%, 0.1);
  padding: 10px;
  margin: 0; /* Adjust margin to ensure items fit */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
}

*/*
button{
    margin: 1%;
    background-color: lightblue;
    color:black;
    font-size: larger;
    font-weight: 900;
}
*/*

.dayna{

    position: absolute;

    background-color: red;
    color: yellow;
    font-weight: 900;
    font-size: 10px;
    top:90%;
    bottom:0%;
    left: 0%;
    position: fixed;
    text-align: center;
    padding: 5px;
    line-height: 70%;
}

