body{
background-color: black;
}

.framev{
    width: 560px;
    height: 315px;
}

.atm{
    margin-top: 5%;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 10%;
    left: 30%;
    height: 69%;
    width: 50%;
    border: 3px solid white;
    background-color: black;
    color: white;
    overflow: scroll;
    scrollbar-color: white yellow;
    scrollbar-width:auto;
    font-weight: 500;
    font-size: x-large;
    line-height: 160%;
  

}
.btne{
    position: absolute;
    top:1px ;
    right: 5px ;
}

.btncss{
    background-color: orange;
    color:black;
    font-weight: 900;
    font-size: xx-large;

}

.btncss:hover{
    background-color: yellow;
    color:black;
    
}


/* Responsive styles for mobile screens */
@media only screen and (max-width: 480px) {



    .framev{
        width: 360px;
        height: 315px;
    }

.rumble{
  width:90%;
  height:60%;
}

.dumbass{
  width:90%;
  height:60%;
}

.atm{
    margin-top: 5%;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 5%;
    left: 0%;
    width: 380px;
    border: 3px solid white;
    background-color: black;
    color: white;
    overflow: scroll;
    scrollbar-color: white yellow;
    scrollbar-width:auto;
    font-weight: 500;
    font-size: large;
    line-height: 160%;
  

}


.btne{
    position: absolute;
    top:1px ;
    right: 5px ;
}

.btncss{
    background-color: orange;
    color:black;
    font-weight: 900;
    font-size: xx-large;

}

.btncss:hover{
    background-color: yellow;
    color:black;
    
}


  }

  .shittalk {
    font-weight: 900;
    color:red;
  }
  .shittalk:hover{
    background-color: white;
    color: red;
  }
  .shittalk:visited{
    background-color: white;
    color: red;
  }

  .jjlink{
    color:rgb(38, 101, 238);
    font-weight: 900;
    background-color: white;
  }

  .jlink{
    display: flex;
    flex-direction: column;
  }
  .jjlink:visited{
    color:rgb(38, 101, 238);
  }

  h3, h2{
    color: yellow;
  }

  /* LOOKS AMAZING THIN BLUE LINE FOR DAYNA TAG
  h4{
    color:rgb(38, 101, 238);
    background-color: white;
  } 
  */
  .famnum{
    color: aqua;
  }

  .famname{
    color: brown;
  }


