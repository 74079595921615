.body{
    color: white;
}
.jp{
    display:flex;
    justify-content: center;
    align-items: center;
width:fit-content;
height:max-content;
background-color: black;
top: 50%;
overflow: visible;
}
.jppp{
    height: 50%;
    width: 65%;
    font-weight: 700;
    font-size: large;
    line-height: 200%;
}
.club{
    height: 75%;
    width: 75%;
}
.imgj{
    height: 30%;
    width: 30%;
}