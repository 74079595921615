.body {
    font-family: "Abril Fatface", serif;
    font-weight: 400;
    font-style: normal;
  }

.navbar{
display:flex;
justify-content: center;
width: 100%;
background-color: white;
font-size: x-large;
justify-content: space-evenly;
font-weight: 500;

}
.banner{
    background-image: url('banner.png');
    background-size: 60%; /* Ensures the image covers the entire area */
    height: 225px;
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repeating */
    
}
.container{
    top: 0%;
    left: 0%;
    background-color: rgb(77, 86, 115);
    

}
a:visited {
    color: black;
}

a:hover {
    background-color: red;
    color: white;
    border-bottom: 2px solid red;
}

a:active {
    color: blue;
    background-color: white;
  }

a{    
    text-decoration: none;
}





@media (max-width: 480px) {
    .banner{
    background-image: url('bannersm.png');
    background-size: 375px; /* Ensures the image covers the entire area */
    height: 150px;
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repeating */
}
    .navbar {
        width: 100%;
        font-size: medium; /* Even smaller font size for very small screens */
    }

    .banner {
        padding: 5px; /* Further adjust padding for very small screens */
    }

    .container {
        padding: 5px; /* Further adjust padding for very small screens */
    }

    a{
        border: 1px solid black;
        font-size: small;
        font-weight: 900;
        text-align: center;
        color: black;
        vertical-align: middle;
        justify-content: center;
    }

    a:visited {
        color: black;
    }
    
    a:hover {
        background-color: red;
        color: white;
        border-bottom: 2px solid red;
    }
    
    a:active {
        color: blue;
        background-color: white;
      }
    
    a{    
        text-decoration: none;
    }
    
}

