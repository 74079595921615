

body{
    color: white;
    bottom: 30px;

}

.kp{

    height: 50%;
    width: 65%;
    font-weight: 700;
    font-size: large;
    line-height: 200%;
}

.kpp{
    display:flex;
    justify-content: center;
    align-items: center;
    line-height: 200%;

    
}

.kh{
    align-self: center;
    font-weight: 700;
    font-size: x-large;
    line-height: 200%; 
}
.k {
    font-weight: 900;
    color:red;
  }
  .k:hover{
    background-color: white;
    color: red;
  }
  .k:visited{
    background-color: white;
    color: red;
  }
  .imgk{
    display: flex;
    justify-content: center;
  }
  .imgkk{
    height: 50%;
    width:50%;
  }
