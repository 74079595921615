.body{
    color: white;
}
.lsp{
    display:flex;
    justify-content: center;
    align-items: center;
}
.lspp{
    height: 50%;
    width: 65%;
    font-weight: 700;
    font-size: large;
    line-height: 200%;
}
.lsh{
    align-self: center;
    font-weight: 700;
    font-size: x-large;
    line-height: 200%; 
}