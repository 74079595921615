.body{
    color: white;
}
.qp{
    display:flex;
    justify-content: center;
    align-items: center;
}
.qqp{
    height: 50%;
    width: 65%;
    font-weight: 700;
    font-size: large;
    line-height: 200%;
}
.qh{
    align-self: center;
    font-weight: 700;
    font-size: x-large;
    line-height: 200%; 
}
.gpic{
    width:60%;
}


@media only screen and (max-width: 480px) {



    .framev{
        width: 360px;
        height: 315px;
    }

    .gpic{
        width:90%;
    }

    .qqp{
        width: 90%;
    }

}